<template>
  <div class="page">
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
      @clearSearch="clearSearch">
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input v-model="searchFormData.agent_name" size="small" clearable placeholder="按代理商名称查询"></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.account_type" placeholder="按结算方式查询" clearable size="small">
          <el-option label="微信" :value="10"> </el-option>
          <el-option label="支付宝" :value="20"> </el-option>
          <el-option label="银行卡" :value="30"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.status" placeholder="按状态查询" clearable size="small">
          <el-option label="审核中" :value="20"> </el-option>
          <el-option label="已结算" :value="30"> </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column slot="table-item" field="agent_name" title="代理商名称" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" field="account_type" title="结算方式" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{ types.account_type[row.account_type] || "未知方式" }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="create_time" title="申请结算时间" align="center" min-width="110px" />
      <vxe-table-column slot="table-item" field="balance_account" title="申请结算金额" align="center" min-width="110px" />
      <vxe-table-column slot="table-item" field="pay_money" title="实际结算金额" align="center" min-width="110px" />
      <vxe-table-column slot="table-item" field="status" title="审核状态" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{ types.status[row.status] || "未知状态" }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="check_user" title="审核人" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" field="check_time" title="审核时间" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" title="操作" align="center" width="100">
        <template v-slot="{ row }">
          <el-button size="small" plain @click="checkOne(row)" v-if="$hasAccess('agent.agent/check') && row.status == 20">结算</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 结算 -->
    <el-dialog title="结算" :visible.sync="checkDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <el-form :model="checkDialogFormData" :rules="rules" ref="checkForm" label-width="140px" class="form">
        <el-form-item label="申请结算金额" size="mini">{{
          checkDialogFormOriData.agent_name
        }}</el-form-item>
        <el-form-item label="申请时间" size="mini">{{
          checkDialogFormOriData.create_time
        }}</el-form-item>
        <el-form-item label="申请结算金额">{{
          checkDialogFormOriData.balance_account
        }}</el-form-item>
        <!-- <el-form-item label="实际结算金额">{{checkDialogFormOriData.pay_money}}</el-form-item> -->
        <el-form-item label="结算方式" prop="account_type">
          <el-radio-group v-model="checkDialogFormData.account_type">
            <el-radio :label="10">微信</el-radio>
            <el-radio :label="20">支付宝</el-radio>
            <el-radio :label="30">银行卡</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveCheck()" v-if="
            $hasAccess('agent.agent/check') &&
            checkDialogFormOriData.status == 20
          ">确 认 结 算
        </el-button>
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'agent-acoount-list',
  components: { Tables },
  data () {
    return {
      // 表格信息
      tableName: '结算单',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      types: {
        account_type: {
          10: '微信',
          20: '支付宝',
          30: '银行卡'
        },
        status: {
          20: '审核中',
          30: '已结算'
        }
      },
      // 结算
      checkDialogShow: false,
      checkDialogFormData: {},
      checkDialogFormOriData: {},
      rules: {
        account_type: [
          { required: true, message: '请选择结算方式', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {},
  activated () {},
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Agent.GetAccountList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 关闭弹窗
    closeDialog () {
      this.checkDialogShow = false
    },
    // 结算
    checkOne (row) {
      this.checkDialogFormOriData = row
      this.checkDialogFormData = {
        account_id: row.account_id
      }
      this.checkDialogShow = true
    },
    // 结算保存
    saveCheck () {
      this.$refs.checkForm.validate(async (valid) => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.checkDialogFormData)
          }
          await this.$api.Agent.CheckAccount(params)
          this.$notify({
            title: '成功',
            message: '结算成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
